<template>
    <section class="app-main">
        <!-- <demo v-if="isDemo"></demo> -->
        <div class="own_courier_end" v-if="!ownCourierEndExceeded">
            <el-alert
                class="alert_own_courier_end"
                :closable="false"
                :title="$t('own_courier_end')"
                type="warning"
                :description="`${timeRemainingForOwnCourierEnd} ${$t('days')} ${$t('left')}`"
            >
            </el-alert>
        </div>
        <pending-restrictions class="restrictions" v-if="hasPendingRestrictions" />
        <restrictions class="restrictions" v-if="hasRestrictions" />
        <transition name="fade" mode="out-in">
            <!-- <router-view :key="key"></router-view> -->
            <router-view></router-view>
        </transition>
        <video-inst v-if="videoInstructionsActive"></video-inst>
    </section>
</template>

<script>
const VideoInstructions = () => import('@/components/YoutubeInstructions');
const Restrictions = () => import('@/components/Restrictions');
const PendingRestrictions = () => import('@/components/PendingRestrictions');

// const Demo = () => import('@/components/Demo');
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
    name: 'AppMain',
    components: {
        'video-inst': VideoInstructions,
        Restrictions,
        PendingRestrictions,
    },
    computed: {
        ...mapGetters({
            videoInstructionsActive: 'app/videoInstructionsActive',
            feedbackActiv: 'user/feedbackActiv',
            hasRestrictions: 'user/hasRestrictions',
            hasPendingRestrictions: 'user/hasPendingRestrictions',
        }),
        timeRemainingForOwnCourierEnd() {
            const today = moment();
            // return number of days from today to 2025.01.01
            return moment('2025-01-01').diff(today, 'days');
        },
        ownCourierEndExceeded() {
            return this.timeRemainingForOwnCourierEnd <= 0;
        },
    },
};
</script>
<style lang="scss" scoped>
.app-main {
    width: 100%;
    position: relative;
    /* overflow: hidden; */

    .restrictions {
        margin: 20px 20px 0 20px;
        padding: 0 20px;
        max-height: 400px;
        overflow-y: auto;
    }
}

.own_courier_end {
    margin: 20px;

    .alert_own_courier_end {
        font-size: 18px !important;
    }

    ::v-deep .el-alert__title {
        font-size: 14px;
    }
    ::v-deep .el-alert__description {
        font-size: 13px;
    }
}
</style>
